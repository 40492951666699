import Vue from "vue";

// Vue.prototype.$uploadUrl = 'http://localhost:5000/file/uploads/'
// Vue.prototype.$ip = 'http://localhost:5000/'



Vue.prototype.$uploadUrl = 'https://xcx.411426414.top/file/uploads/'
Vue.prototype.$imageIp = 'https://xcx.411426414.top/uploads/'
Vue.prototype.$ip = 'https://xcx.411426414.top/'

// 上传路径
// Vue.prototype.$uploadUrl = 'http://localhost:5000/file/uploads/'
// Vue.prototype.$ip = 'http://localhost:5000/'
