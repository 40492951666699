import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
import './assets/css/global.css'
import './utils/axios'
import './utils/cofig'


import preview from 'vue-photo-preview'
import 'vue-photo-preview/dist/skin.css'

Vue.use(preview)

// 使用 mavon-editor 实现 MD 展示与编辑
import mavonEditor from 'mavon-editor' // components
import 'mavon-editor/dist/css/index.css' // styles
Vue.use(mavonEditor)

Vue.config.productionTip = false

import _ from 'lodash';


// 自定义过滤器,优化时间
Vue.filter('dateFormat', (originval) => {
  const dt = new Date(parseInt(originval))
  const y = dt.getFullYear()
  const m = (dt.getMonth() + 1 + '').padStart(2, '0')
  const d = (dt.getDate() + '').padStart(2, '0')
  const hh = (dt.getHours() + '').padStart(2, '0')
  const mm = (dt.getMinutes() + '').padStart(2, '0')
  const ss = (dt.getSeconds() + '').padStart(2, '0')
  return `${y}-${m}-${d} ${hh}:${mm}:${ss}`
})

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

import Router from 'vue-router'
import Vue from "vue";
import axios from "@/utils/axios";
import moment from "moment";

const routerPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error => error)
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
