import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {path: '/', redirect: '/home'},
  {path: '/login', name: 'Login', component: () => import('../views/Login'), meta:{title: '登录'}},
  {path: '/cov', name: 'Cov', component: () => import('../components/Cov'), meta:{title: '疫情分析'}},
  {
    path: '/home',
    name: 'Home',
    redirect: '/home',
    component: () => import('../views/Home.vue'),
    children: [
      {path: '/home', name: 'About', component: () => import('../views/About.vue'), meta:{title: '首页'}},
      {path: '/exhibitionDetailed', name: 'ExhibitionDetailed', component: () => import( '../views/exhibition/exhibitionDetailed'), meta:{title: '展品详情'}},
      {path: '/exhibition', name: 'Exhibition', component: () => import( '../views/exhibition/exhibition'), meta:{title: '展品'}},
      {path: '/welcome', name: 'Welcome', component: () => import('../views/Welcome'), meta:{title: '个人中心'}},
      {path: '/exhibits', name: 'Exhibits', component: () => import('../views/Exhibits'), meta:{title: '展品管理'}},
      {path: '/file', name: 'File', component: () => import('../views/File'), meta:{title: '文件管理'}},
      {path: '/pdf', name: 'PDF', component: () => import('../components/PDF'), meta:{title: 'PDF'}},
      {path: '/ybh', name: 'YBH', component: () => import('../views/YBH'), meta:{title: '园博会'}},
      // {path: '/zsb', name: 'Zsb', component: () => import( '../views/regNumber'), meta:{title: '专升本'}},
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
