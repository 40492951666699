import axios from 'axios'
import Vue from 'vue'
// 导入nprogress包对应的JS和CSS
import nprogress from 'nprogress'
import 'nprogress/nprogress.css'
import {Loading} from "element-ui";
import router from "@/router";

// axios.defaults.baseURL = 'http://localhost:5000/'
axios.defaults.baseURL = 'https://xcx.411426414.top/'

// 超时时间
axios.defaults.timeout = 5000
// 添加一个请求拦截器

let loadingInstace =""
// axios.interceptors.request.use(config => {
//   // loadingInstace = Loading.service({ fullscreen: true })
//   nprogress.start()
//   config.headers.Authorization = window.sessionStorage.getItem('token')
//   return config
// }, error => {
//   // loadingInstace.close()
//   return Promise.reject(error)
// })
// // 添加一个响应拦截器
// axios.interceptors.response.use(res => {
//   // loadingInstace.close()
//   nprogress.done();
//   return res
// }, error => {
//   nprogress.done();
//   // loadingInstace.close()
//   router.push('/');
//   window.sessionStorage.removeItem('token')
//   return Promise.reject(error)
// })

// 挂载
Vue.prototype.$http = axios
